.loadingContainer svg{    
    position:absolute;
    top:calc(50% - 30px);
    left:calc(50% - 30px);}

      
  thead .sticky-table-row{
    border-bottom-color: var(--bs-gray-100);
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  .sticky-table-cell{
    vertical-align: middle;
  }

  .payment .k-table-td, .payment .k-table-th{
    font-size: 0.85rem!important;
  }

  .payment .k-table-thead th{
   border-right:1px solid #DBDFE9!important;
  }



  .payment  .k-grid-edit-cell {
    font-size: 0.85rem!important;
  }

  .detailTable tr{
    border-width: 1px!important;
  }

