body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media (min-width: 992px) {
  div, main, ol, pre, span, ul {
    scrollbar-width: auto;
    scrollbar-color: var(--bs-scrollbar-color) transparent;
  }
}



::-webkit-scrollbar-thumb:hover {
  width: 8px!important; /* 마우스 오버 시 핸들의 너비를 더 두껍게 */
  height: 8px!important; /* 마우스 오버 시 핸들의 너비를 더 두껍게 */
}


::-webkit-scrollbar:hover {
  width: 8px!important; /* 마우스 오버 시 핸들의 너비를 더 두껍게 */
  height: 8px!important; /* 마우스 오버 시 핸들의 너비를 더 두껍게 */
}
