.form-select.form-select-solid {
    background-color: var(--bs-gray-100);
    border-color: var(--bs-gray-100);
    color: var(--bs-gray-700);
    transition: none;
}

.form-select .k-button-icon {
    display: none!important;
}
/* .k-input-md .k-input-button, .k-input-md .k-spinner-increase, .k-input-md .k-spinner-decrease, .k-picker-md .k-input-button, .k-picker-md .k-spinner-increase, .k-picker-md .k-spinner-decrease {
    display: none;
} */
.form-select.k-picker-solid {
    background-image:  url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%2378829D%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
}

/* .k-picker-solid {
    background: no-repeat;
} */


.k-picker {
    padding-top: .625rem;
    padding-bottom: .625rem;
    padding-left: 1.2rem;
    font-size: 1.15rem;
    border-radius: .625rem;
    box-shadow: none;
    height: auto;
    outline: 0 !important;
    background-color: #F1F1F2;  
}


.k-pager-sizes .k-picker {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 0;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    outline: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.42857;
    font-weight: normal;
    text-align: start;
    box-shadow: none;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: stretch;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-appearance: none;  
}



/* .k-input-solid{
    min-height: calc(1.5em + 1.65rem + 2px);
    padding: 0.825rem 1.5rem;
    font-size: 1.15rem;
    border-radius: 0.625rem;
    display: block;
    width: 100%;
    padding: 0.775rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    color: var(--bs-gray-700);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: 1px solid var(--bs-gray-300);
    appearance: none;
    border-radius: 0.475rem;
    box-shadow: false;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */

input.k-input, textarea.k-textarea{
    padding: 0.775rem 1rem;
}

.k-picker-solid:focus-within, .k-input-solid:focus, .k-input-solid.k-focus {
    box-shadow: none!important;
}

.k-focus{
    box-shadow: none!important;
}

.k-grid{
    --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(var(--bs-gray-100-rgb), 0.75);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: var(--bs-gray-100);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: var(--bs-gray-100);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  caption-side: bottom;
  border-collapse: collapse;
  caption-side: bottom;
  border-collapse: collapse;

}

.k-grid .k-grid-header {
border-color: inherit;
  border-style: solid;
  border-width: 0;
  vertical-align: bottom;
}

.k-table-thead{
    border-color: inherit;
    border-style: solid;
    border-width: 0!important;
}

.k-table-thead tr {
    color: var(--bs-text-muted) !important;
    --bs-bg-rgb-color: var(--bs-light-rgb);
  background-color: var(--bs-light) !important;
  border:none !important;
}

.k-grid-toolbar {
    border-width: 0;
    background-color: transparent;
}

.k-grid .k-grid-header .k-table-th {
    border: none;
}

.k-grid-content .k-table-row:last-child > td, .k-grid-content .k-table-row:last-child > .k-table-td, .k-grid-content-locked .k-table-row:last-child > td, .k-grid-content-locked .k-table-row:last-child > .k-table-td {
    border-left-width: 0 ;
}

.k-table-alt-row {
background-color: transparent!important;
}

.k-grid-header, .k-grid-header-wrap, .k-grouping-header, .k-grid .k-table-th, .k-grid td, .k-grid .k-table-td, .k-grid-footer, .k-grid-footer-wrap, .k-grid-content-locked, .k-grid-footer-locked, .k-grid-header-locked, .k-filter-row > .k-table-th, .k-filter-row > td, .k-filter-row > .k-table-td {
border-left-width: 0;
} 

td {
    color: var(--bs-text-dark) !important;  --bs-text-opacity: 1;font-weight: 600 !important;font-size: 1.075rem !important;
}

.design table{
    padding: .75rem .75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0.75rem;
  color: var(--bs-table-color-state,var(--bs-table-color-type,var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state,var(--bs-table-bg-type,var(--bs-table-accent-bg)));
}
